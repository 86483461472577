.logo-fade-in {
  animation: fade-in 1.5s ease-out forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.image-card-fade-in {
  opacity: 1 !important;
  transform: translateY(0px) !important;
}

.image-card {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}
